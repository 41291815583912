import React from "react";

import {
  DefaultButton,
  getColorFromString,
  IButtonProps,
  IButtonStyles,
  ITheme,
  mergeStyleSets,
  updateA,
  useTheme
} from "@bps/fluent-ui";

interface DangerButtonProps extends IButtonProps {}

export const dangerButtonStyles = (theme: ITheme): IButtonStyles => ({
  root: {
    color: theme.semanticColors.errorText,
    borderColor: theme.semanticColors.errorText,
    background: theme.semanticColors.errorBackground
  },
  rootHovered: {
    color: theme.semanticColors.errorText,
    borderColor: theme.semanticColors.errorText,
    background: updateA(getColorFromString(theme.semanticColors.errorText)!, 20)
      ?.str
  },
  rootPressed: {
    color: theme.palette.white,
    borderColor: theme.semanticColors.errorText,
    background: theme.semanticColors.errorText
  },
  icon: {
    color: theme.semanticColors.errorText
  },
  iconPressed: {
    color: theme.palette.white
  }
});

export const DangerButton: React.FC<DangerButtonProps> = props => {
  const theme = useTheme();
  return <DefaultButton {...props} styles={dangerButtonStyles(theme)} />;
};

export const DangerMenuItem: React.FC<DangerButtonProps> = props => {
  const theme = useTheme();
  return (
    <DefaultButton
      styles={mergeStyleSets(dangerButtonStyles(theme), {
        root: {
          background: "transparent",
          width: "100%",
          padding: "0px 8px 0px 4px",
          border: 0,
          justifyContent: "space-between"
        },
        label: { width: "fit-content" }
      })}
      {...props}
    />
  );
};
