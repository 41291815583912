import React from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage, useMount } from "react-use";

import {
  FontIcon,
  noWrap,
  Pivot,
  PivotItem,
  Stack,
  useTheme
} from "@bps/fluent-ui";

import { TabsNavContext } from "./TabsNavContext";
import {
  DEFAULT_ITEM_KEY,
  TabNavItemDetails,
  TabsNavProps
} from "./TabNav.types";

export function TabsNav<D extends unknown>({
  tabKey,
  selectedTab = DEFAULT_ITEM_KEY,
  renderTab,
  renderDefault,
  renderLink,
  defaultPath,
  navigatePath
}: TabsNavProps<D>) {
  const theme = useTheme();

  const [storedTabs = [], setStoredTabs] = useLocalStorage<TabNavItemDetails[]>(
    `${tabKey}-tabs`
  );

  useMount(() => {
    const cachedTab = storedTabs.find(s => s.id === selectedTab);
    if (selectedTab !== DEFAULT_ITEM_KEY && !cachedTab) {
      setStoredTabs(prev => [...(prev ?? []), { id: selectedTab }]);
    }
  });

  const navigate = useNavigate();

  const _setSelectedKey = (details?: TabNavItemDetails<D>) => {
    if (details) {
      const path = navigatePath(details);
      navigate(path);
    } else {
      navigate(defaultPath!);
    }
  };

  const addTab = (details: TabNavItemDetails) => {
    const index = storedTabs?.findIndex(t => t.id === details.id);

    if (index === undefined || index < 0) {
      const newTabs = [...(storedTabs || []), details];
      setStoredTabs(newTabs);
    }
    _setSelectedKey(details);
  };

  const closeTab = (id: string) => {
    const item = storedTabs?.find(t => t.id === id);
    const newTabs = storedTabs.filter(i => i.id !== id);
    setStoredTabs(newTabs);

    if (!newTabs.length) {
      _setSelectedKey();
    } else {
      if (item?.id === selectedTab) {
        const isFirstTenantTab =
          storedTabs.findIndex(tab => tab.id === selectedTab) === 0;

        // if selected tab is the first in the list, we navigate to the next tab otherwise to the previous one
        const newTab = isFirstTenantTab ? storedTabs[1] : storedTabs[0];
        _setSelectedKey(newTab);
      }
    }
  };

  const onLinkClick = (item: any) => {
    const tab = storedTabs.find(tab => String(item.key).endsWith(tab.id));
    _setSelectedKey(tab);
  };
  return (
    <TabsNavContext.Provider value={{ addTab, closeTab }}>
      <Pivot
        style={{ height: "100%", display: "flex", flexDirection: "column" }}
        linkFormat="tabs"
        selectedKey={selectedTab}
        defaultSelectedKey={selectedTab}
        onLinkClick={onLinkClick}
        overflowBehavior="menu"
        styles={{
          root: {
            backgroundColor: theme.semanticColors.bodyBackground,
            overflowX: "auto",
            overflowY: "hidden",
            minHeight: 44
          },
          itemContainer: {
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "row",
            overflow: "hidden",
            marginTop: theme.spacing.s1,

            // The PivotItem renders a superfluous 'div' :'(
            "> div": { width: "100%" }
          },
          overflowMenuButton: {
            backgroundColor: theme.palette.neutralLighter
          },
          linkIsSelected: {
            span: { color: theme.palette.white },
            ":first-child": {
              color: theme.palette.white,
              fontWeight: 600
            }
          },
          linkInMenu: {
            padding: 8,
            height: "auto",
            width: 250,
            justifyContent: "start",
            "&:hover": { color: "black" },
            span: { width: "100%" },
            ".tabs-nav-remove-button": { display: "none" }
          },
          link: {
            maxWidth: 250,
            borderLeft: "1px solid ",
            borderLeftColor: theme.palette.neutralLighter,
            ":hover": { span: { color: theme.palette.white } },
            ":first-child": {
              fontWeight: 600,
              fontSize: 20
            }
          }
        }}
      >
        <PivotItem
          style={{
            height: "100%"
          }}
          itemKey={DEFAULT_ITEM_KEY}
          headerText="+"
        >
          {renderDefault?.()}
        </PivotItem>

        {storedTabs?.map(details => {
          return (
            <PivotItem
              style={{ height: "100%" }}
              key={details.id}
              itemKey={details.id}
              onRenderItemLink={
                renderLink
                  ? props => (
                      <Stack
                        horizontal
                        verticalAlign="center"
                        tokens={{ childrenGap: 16 }}
                        grow
                        styles={{ root: { ...noWrap } }}
                      >
                        {renderLink?.({
                          details,
                          isSelected: selectedTab === props?.itemKey
                        })}
                        <FontIcon
                          className="tabs-nav-remove-button"
                          onClick={evt => {
                            evt.stopPropagation();
                            closeTab(details.id);
                          }}
                          iconName="Cancel"
                        />
                      </Stack>
                    )
                  : undefined
              }
              headerText={details.name}
            >
              {renderTab?.(details, sectionId => {
                setStoredTabs(() => {
                  return storedTabs?.map(t => {
                    if (details.id === t.id) {
                      return { ...t, sectionId };
                    }
                    return t;
                  });
                });
              })}
            </PivotItem>
          );
        })}
      </Pivot>
    </TabsNavContext.Provider>
  );
}
